const iconSize = 19;
const textSize = [14, null, 16];
const checkBoxTextMt = ["-2px", null, "-2px"];
const dashboardItemHeight = "600px";
const dashboardItemMinWidth = "350px";
const responsiveSpacing = [4, null, 5];
const responsiveSpacing2 = [6, null, 8];
const fcMaxHeight = `calc(100dvh - (74px * 2))`;

export {
  iconSize,
  textSize,
  checkBoxTextMt,
  dashboardItemHeight,
  dashboardItemMinWidth,
  responsiveSpacing,
  responsiveSpacing2,
  fcMaxHeight,
};
